<template>
  <BarLoader 
    :color="color"
    :height="height"
    :width="width"
  />
</template>

<script>
import { BarLoader } from '@saeris/vue-spinners'
export default {
  components:{
    BarLoader
  },
  data: () => ({
    color: '#6683F8',
    height: 30,
    width: 500
  })
}
</script>

<style scoped>

</style>
<template>
  <div class="text-center">
    <v-dialog v-model="$store.state.error.errorStatus" width="500" persistent>
      <v-card>
        <header>
          <v-card-title
            style="
              background-color: rgb(93, 109, 190);
              color: white;
              font-family: notosans;
            "
            class="errorcardtitle"
          >
            메시지
          </v-card-title>
        </header>
        <v-card-text>
          <p style="font-size: 23px; font-family: notosans; margin-top: 30px">
            {{ $store.state.error.msg }}
          </p>
        </v-card-text>

        <v-divider />

        <v-card-actions>
          <v-spacer />
          <v-btn
            style="font-family: notosans; font-size: 17px"
            color="black"
            text
            @click="errorCheck"
          >
            확인
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  methods: {
    async errorCheck() {
      await this.$store.dispatch("ERROR_RESET", {
        result: true,
        msg: "",
        code: "",
        errorStatus: false,
      });

      this.$router.go(0);
    },
  },
};
</script>

<style>
@font-face {
  font-family: "notosans";
  src: url("../assets/font/NotoSansKR-Bold.otf");
}
#errorcardtitle {
  background-color: rgb(93, 109, 190);
  color: white;
  width: 500;
}
</style>
import {
  login,
  raidInfo,
  bossCreateList,
  bossRaidList,
  raidMake,
  raidDelete,
  noticeList,
  notice,
  noticeChange,
  noticeChangeOrder,
  noticeDelete,
  messageList,
  messageReserve,
  messageDelete,
  messageUpdate,
  nicknamecheck,
  nowlength,
  signuptypeusers,
  dau,
  raidcount,
  arcount
} from '../api/api-list'

const SPINNER = async ({ commit }, status) => {
  await commit('SET_SPINNER', status)
}
const ERROR_RESET = async ({ commit }, data) => {
  await commit('SET_ERROR', data)
}
const MODAL_RESET = async ({ commit }) => {
  await commit('SET_MODAL')
}
const LOGIN = async ({ commit }, loginData) => {
  const test = await login(loginData)
  await commit('SET_LOGIN', test)
}
const RAID_INFO = async ({ commit }) => {
  const { data } = await raidInfo()
  await commit('SET_RAID_INFO', data)
}
const RAID_BOSS_CREATE_LIST = async ({ commit }) => {
  const { data } = await bossCreateList()
  await commit('SET_RAID_BOSS_CREATE_LIST', data)
}
const RAID_BOSS_RESERVATION_LIST = async ({ commit }) => {
  const { data } = await bossRaidList()
  await commit('SET_RAID_BOSS_RESERVATION_LIST', data)
}
const RAID_MAKE = async ({ commit }, bossData) => {
  const { data } = await raidMake(bossData)
  await commit('SET_RAID_MAKE', data)
}
const RAID_DELETE = async ({ commit }, num) => {
  const { data } = await raidDelete(num)
  await commit('SET_RAID_DELETE', data)
}
const NOTICE_LIST = async ({ commit }) => {
  const { data } = await noticeList()
  await commit('SET_NOTICE_LIST', data)
}
const NOTICE = async ({ commit }, file) => {
  const { data } = await notice(file)
  await commit('SET_NOTICE', data)
}
const NOTICE_UPDATE = async ({ commit }, file) => {
  const { data } = await noticeChange(file)
  await commit('SET_NOTICE_UPDATE', data)
}
const CHANGE_NOTICE_ORDER = async ({ commit }, order) => {
  const changeData = { changeData: order }
  const { data } = await noticeChangeOrder(changeData)
  await commit('SET_CHANGE_NOTICE_ORDER', data)
}
const DELETE_NOTICE = async ({ commit }, name) => {
  const { data } = await noticeDelete(name)
  await commit('SET_DELETE_NOTICE', data)
}
const MESSAGE_LIST = async ({ commit }) => {
  const { data } = await messageList()
  await commit('SET_MESSAGE_LIST', data)
}
const MESSAGE_CREATE = async ({ commit }, message) => {
  console.log(message)
  const { data } = await messageReserve(message)
  await commit('MESSAGE_CREATE', data)
}
const MESSAGE_UPDATE = async ({ commit }, message) => {
  console.log(message)
  const { data } = await messageUpdate(message)
  await commit('MESSAGE_UPDATE', data)
}
const MESSAGE_DELETE = async ({ commit }, message) => {
  console.log(message)
  const { data } = await messageDelete(message)
  console.log(data)
  await commit('MESSAGE_DELETE', data)
}
const NICK_CHECK = async ({ commit }, nick) => {
  const { data } = await nicknamecheck(nick)
  console.log(data.result)

  await commit('NICKNAME_CHECK', data.result)
}
const NOW_LENGTH = async ({ commit }) => {
  const { data } = await nowlength()
  await commit('SET_NOW_LENGTH', data.data)
}
const SIGNUP_TYPE = async ({ commit }) => {
  const { data } = await signuptypeusers()
  return data.data
}
const DAU = async ({ commit }, info) => {
  const { data } = await dau(info.start, info.end)
  return data.data
}
const RAIDCOUNT = async ({ commit }, info) => {
  const { data } = await raidcount(info.start, info.end)
  return data.data
}
const ARCOUNT = async ({ commit }, info) => {
  const { data } = await arcount(info.start, info.end, info.type)
  return data.data
}

export {
  SPINNER,
  ERROR_RESET,
  MODAL_RESET,
  LOGIN,
  RAID_INFO,
  RAID_BOSS_CREATE_LIST,
  RAID_BOSS_RESERVATION_LIST,
  RAID_MAKE,
  RAID_DELETE,
  NOTICE_LIST,
  NOTICE,
  NOTICE_UPDATE,
  CHANGE_NOTICE_ORDER,
  DELETE_NOTICE,
  MESSAGE_LIST,
  MESSAGE_CREATE,
  MESSAGE_UPDATE,
  MESSAGE_DELETE,
  NICK_CHECK,
  NOW_LENGTH,
  SIGNUP_TYPE,
  DAU,
  RAIDCOUNT,
  ARCOUNT
}

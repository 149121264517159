import Vue from 'vue'
import Vuex from 'vuex'
import persistedstate from 'vuex-persistedstate'
import * as mutations from './mutations'
import * as actions from './action'

Vue.use(Vuex)

export default new Vuex.Store({
  plugins:[
    persistedstate({
      storage: window.sessionStorage,
      reducer: state => ({
        // session store에 일회성이 아닌 저장해야하는 값
        token: state.token,
        boss: {}
      })
    })
  ],
  state: {
    error: {
      msg: '',
      code: '',
      result: true,
      errorStatus: false
    },
    modal: {
      msg: '',
      status: false
    },
    token:'',
    loading: false,
    boss: {},
    bossList: [],
    bossCreateList: [],
    raidList: [],
    noticeList: [],
    noticeDate : '',
    msg_list: [],
    screensetting: 0,
    isuser: false,
    nowlength: 0,
  },
  getters: {
    logCheck (state) {
      return state.token !== ''
    }
  },
  mutations,
  actions,
  modules: {
  }
})

<template>
  <v-container @keyup.enter="login">
    <v-row justify="center">
      <div align="center" style="margin-top: 22vh">
        <img style="width: 500px" src="../assets/machun_admin_logo.png" />
      </div>
    </v-row>
    <v-row justify="center">
      <div
        align="center"
        style="
          background-color: rgb(240, 240, 240);
          width: 600px;
          height: 400px;
          border-radius: 50px;
        "
      >
        <br />
        <br />
        <br />
        <br />
        <br />
        <v-row style="width: 300px" justify="center">
          <v-text-field
            label="아이디"
            flat
            outlined
            v-model="id"
            style="font-size: 17px"
            solo
          ></v-text-field>
        </v-row>
        <br />
        <v-row style="width: 300px" justify="center">
          <v-text-field
            label="비밀번호"
            flat
            outlined
            v-model="password"
            type="password"
            style="font-size: 17px"
            solo
          ></v-text-field>
        </v-row>
        <br />
        <br />
        <br />
        <v-row justify="center">
          <v-btn
            @click="login()"
            style="
              width: 300px;
              height: 50px;
              background-color: rgb(93, 109, 190);
              font-size: 21px;
              font-weight: bold;
              color: white;
            "
            ><b>로그인</b></v-btn
          >
        </v-row>
      </div>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      id: "",
      password: "",
    };
  },
  methods: {
    login() {
      const id = this.id;
      const password = this.password;
      if (id == "" || password == "") {
        return;
      }
      this.$store.dispatch("LOGIN", { id, password });
    },
  },
};
</script>
import dayjs from 'dayjs'
import router from '../router/router'

const SET_SPINNER = async (state, status) => {
  state.loading = status
}
const SET_ERROR = async (state, req) => {
  state.error.msg = req.msg
  state.error.code = req.code
  state.error.result = req.result
  state.error.errorStatus = req.errorStatus
}
const SET_MODAL = async (state) => {
  state.modal.msg = ''
  state.modal.status = false

  location.href = process.env.VUE_APP_FRONT_URL
}
const SET_LOGIN = async (state, res) => {
  console.log(res)
  state.loading = false
  if (res.data.result === false) {
    state.error.msg = '로그인 정보가 잘못되었습니다.'
    state.error.code = res.code
    state.error.result = res.result
    state.error.errorStatus = true
  } else {
    state.token = res.data.data.token
    //$cookies.set("user", state.token);
    //location.href = process.env.VUE_APP_FRONT_URL;
    router.replace({ path: '/raid' })
  }
}
const SET_RAID_INFO = async (state, boss) => {
  //const setDate = new Date(boss.data.setDate)
  //const expirationDate = new Date(boss.data.expirationDate)
  state.boss = boss.data
  if (state.boss) {
    state.boss.setDate = state.boss.setDate.replace('T', ' ')
    state.boss.setDate = state.boss.setDate.replace('.000Z', '')

    state.boss.expirationDate = state.boss.expirationDate.replace('T', ' ')
    state.boss.expirationDate = state.boss.expirationDate.replace('.000Z', '')
  } else {
    state.boss = {}
  }
}
const SET_RAID_BOSS_CREATE_LIST = async (state, list) => {
  list.data[0].uid = String(list.data[0].uid)
  list.data[0].rewardKill = String(list.data[0].rewardKill)
  let arr = []

  for (let i = 0; i < list.data.length; i++) {
    list.data[i].periodname =
      list.data[i].name + ' ' + `(${list.data[i].level})`
  }

  for (let i = 1; i < list.data.length; i++) {
    arr.push(list.data[i])
  }

  state.bossCreateList = arr
}
const SET_RAID_BOSS_RESERVATION_LIST = async (state, list) => {
  const raidListData = list.data
  if (raidListData) {
    for (const a of raidListData) {
      a.setDate = a.setDate.replace('T', ' ')
      a.setDate = a.setDate.replace('.000Z', '')

      a.expirationDate = a.expirationDate.replace('T', ' ')
      a.expirationDate = a.expirationDate.replace('.000Z', '')
      //a.expirationDate = dayjs(a.expirationDate)
    }
    state.raidList = list.data
  }
}
const SET_RAID_MAKE = async (state, req) => {
  state.loading = false
  if (req.result === false) {
    state.error.msg = req.msg
    state.error.code = req.code
    state.error.result = req.result
    state.error.errorStatus = true
  } else {
    state.error.msg = '레이드 예약이 완료되었습니다.'
    state.error.errorStatus = true
  }
}

const SET_RAID_DELETE = async (state, req) => {
  state.loading = false
  if (req.result === false) {
    state.error.msg = req.msg
    state.error.code = req.code
    state.error.result = req.result
    state.error.errorStatus = true
  } else {
    state.modal.msg = '삭제 되었습니다.'
    state.modal.status = true
    const raidListData = req.data
    for (const a of raidListData) {
      a.setDate = dayjs(a.setDate).format('YYYY/MM/DD HH:mm')
      a.expirationDate = dayjs(a.expirationDate).format('YYYY/MM/DD HH:mm')
    }
    state.raidList = req.data
  }
}

const SET_NOTICE_LIST = async (state, req) => {
  state.loading = false
  if (req.result === false) {
    state.error.msg = req.msg
    state.error.code = req.code
    state.error.result = req.result
    state.error.errorStatus = true
  } else {
    const noticeList = req.data.noticeList
    const noticeDate = dayjs(req.data.date).format('YYYY/MM/DD HH:mm')
    state.noticeDate = noticeDate
    state.noticeList = noticeList
  }
}

const SET_NOTICE = async (state, req) => {
  state.loading = false
  if (req.result === false) {
    state.error.msg = req.msg
    state.error.code = req.code
    state.error.result = req.result
    state.error.errorStatus = true
  } else {
    state.error.msg = '공지가 생성되었습니다.'
    state.error.errorStatus = true
  }
}

const SET_NOTICE_UPDATE = async (state, req) => {
  state.loading = false
  if (req.result === false) {
    state.error.msg = req.msg
    state.error.code = req.code
    state.error.result = req.result
    state.error.errorStatus = true
  } else {
    state.error.msg = '공지가 수정되었습니다.'
    state.error.errorStatus = true
  }
}

const SET_CHANGE_NOTICE_ORDER = async (state, req) => {
  state.loading = false
  if (req.result === false) {
    state.error.msg = req.msg
    state.error.code = req.code
    state.error.result = req.result
    state.error.errorStatus = true
  } else {
    state.error.msg = '공지 순서가 변경되었습니다.'
    state.error.errorStatus = true
  }
}

const SET_DELETE_NOTICE = async (state, req) => {
  state.loading = false
  if (req.result === false) {
    state.error.msg = req.msg
    state.error.code = req.code
    state.error.result = req.result
    state.error.errorStatus = true
  } else {
    state.error.msg = '공지가 삭제되었습니다.'
    state.error.errorStatus = true
  }
}

const SET_MESSAGE_LIST = async (state, res) => {
  if (res.result == false) {
    return
  }
  state.msg_list = res.data
}

const MESSAGE_CREATE = async (state, req) => {
  state.loading = false
  if (req.result === false) {
    state.error.msg = req.msg
    state.error.code = req.code
    state.error.result = req.result
    state.error.errorStatus = true
  } else {
    state.error.msg = '예약 메시지가 생성되었습니다.'
    state.error.errorStatus = true
  }
}

const MESSAGE_UPDATE = async (state, req) => {
  state.loading = false
  if (req.result === false) {
    state.error.msg = req.msg
    state.error.code = req.code
    state.error.result = req.result
    state.error.errorStatus = true
  } else {
    state.error.msg = '예약 메시지가 변경되었습니다.'
    state.error.errorStatus = true
  }
}

const MESSAGE_DELETE = async (state, req) => {
  state.loading = false
  if (req.result === false) {
    state.error.msg = req.msg
    state.error.code = req.code
    state.error.result = req.result
    state.error.errorStatus = true
  } else {
    state.error.msg = '예약 메시지가 삭제되었습니다.'
    state.error.errorStatus = true
  }
}

const NICKNAME_CHECK = async (state, req) => {
  state.isuser = req
}

const SET_NOW_LENGTH = async (state, res) => {
  state.nowlength = res
}

export {
  SET_SPINNER,
  SET_ERROR,
  SET_MODAL,
  SET_RAID_INFO,
  SET_RAID_BOSS_CREATE_LIST,
  SET_RAID_BOSS_RESERVATION_LIST,
  SET_LOGIN,
  SET_RAID_MAKE,
  SET_RAID_DELETE,
  SET_NOTICE_LIST,
  SET_NOTICE,
  SET_NOTICE_UPDATE,
  SET_CHANGE_NOTICE_ORDER,
  SET_DELETE_NOTICE,
  SET_MESSAGE_LIST,
  MESSAGE_CREATE,
  MESSAGE_UPDATE,
  MESSAGE_DELETE,
  NICKNAME_CHECK,
  SET_NOW_LENGTH
}

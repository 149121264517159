<template>
  <v-app id="body">
    <v-main>
      <Error v-show="$store.state.error.errorStatus === true" />
      <SpinnerComponent
        v-show="loading === true"
        :class="{ loading: $store.state.loading }"
      />
      <div>
        <router-view
          v-show="
            $store.state.token === '' &&
            $store.state.error.errorStatus === false
          "
        />
      </div>
      <div
        v-show="
          $store.state.token !== '' && $store.state.error.errorStatus === false
        "
        class="background"
      >
        <Side :route="_route" />
        <router-view />
      </div>
    </v-main>
  </v-app>
</template>

<script>
import Side from "./components/SideMenu.vue";
import SpinnerComponent from "./components/SpinnerComponent.vue";
import Error from "./components/ErrorModal.vue";
import Login from "./components/LoginComponent.vue";

export default {
  name: "App",
  components: {
    SpinnerComponent,
    Error,
    Side,
    Login,
  },
  data: () => ({}),
  created() {},
  computed: {
    loading() {
      return this.$store.state.loading;
    },
    _route() {
      return this.$route.path;
    },
  },
  methods: {
    platform(e) {
      let width = window.innerWidth;
      let height = window.innerHeight;
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        if (width > height) {
          return window.innerHeight / 1000;
        }
        return window.innerWidth / 1400;
      } else {
        return 1;
      }
    },

    platform2(e) {
      var minWidth = 1400;
      //var body = document.getElementsByTagName("body")[0];
      if (window.innerWidth < minWidth) {
        return window.innerWidth / minWidth;
      } else return 1;
    },
  },
};
</script>
<style>
.loading {
  margin: 0 auto;
  z-index: 99999;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: rgba(0, 0, 0, 0.8) 0 0 0 9999px;
  background-color: #ffffff;
}

.background {
  width: 100%;
  min-width: 1900px;
  min-height: 1200px;
  background-image: linear-gradient(
    to bottom,
    rgb(204, 210, 240) 40%,
    rgb(238, 238, 238) 40%
  );
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  overflow: hidden;
  float: left;
}

#body {
  zoom: v-bind(platform2());
}
</style>
import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/store-index'

Vue.use(VueRouter)

const routes = [
  {
    path: '*',
    name: 'access-block',
    component: () => import('../views/AccessBlockView.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/LoginPageView')
  },
  {
    path: '/',
    name: 'home',
    component: () => import('../views/SeperateRouteView')
  },
  // {
  //   // main
  //   path: '/',
  //   props: true,
  //   name: 'dash-board',
  //   component: () => import('../views/DashBoardPageView'),
  //   meta: {
  //     auth: true
  //   }
  // },
  {
    path: '/raid',
    props: true,
    name: 'raid',
    component: () => import('../views/RaidView.vue'),
    meta: {
      auth: true
    }
  },
  {
    path: '/notice',
    props: true,
    name: 'notice',
    component: () => import('../views/NoticeView.vue'),
    meta: {
      auth: true
    }
  },
  {
    path: '/message',
    props: true,
    name: 'message',
    component: () => import('../views/MessageView.vue'),
    meta: {
      auth: true
    }
  },
  {
    path: '/log',
    props: true,
    name: 'log',
    component: () => import('../views/LogView.vue'),
    meta: {
      auth: true
    }
  }
]

const router = new VueRouter({
  mode: 'hash',
  routes
})

router.beforeEach((to, from, next) => {
  if (to.meta.auth && !store.getters.logCheck) {
    next('/login')
    return
  }
  next()
})

export default router

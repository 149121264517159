<template>
  <v-card class="sidebackground" style="border-radius: 30px">
    <v-card class="sidemenulogo" />

    <div style="margin-top: 100px" class="btndiv">
      <button @click="goraid()" id="raidbtn" class="raidbtn" plain></button>
      <!-- <button v-if="state == 0" id="raidbtnclicked" plain></button> -->
      <br />
      <button
        @click="gonotice()"
        id="noticebtn"
        class="noticebtn"
        plain
      ></button>
      <!-- <button v-if="state == 1" id="noticebtnclicked" plain></button> -->
      <br />
      <button @click="gomsg()" id="msgbtn" class="msgbtn" plain></button>
      <!-- <button disabled v-if="state == 2" id="msgbtnclicked" plain></button> -->
      <button @click="golog()" id="logbtn" class="logbtn" plain></button>

      <v-btn style="margin-top: 90px" @click="logout" id="logoutbtn"
        >로그아웃</v-btn
      >

      <v-btn style="margin-top: 40px" id="companylogo" disabled plain />
    </div>
  </v-card>
</template>

<script>
export default {
  data: () => {
    return {
      state: "",
    };
  },
  mounted() {
    // document.getElementById("raidbtn").className = "raidbtnclicked";
  },
  watch: {
    route(new_route) {
      console.log(new_route);
      this.route = new_route;
      if (this.route == "/raid") {
        document.getElementById("raidbtn").className = "raidbtnclicked";
        document.getElementById("noticebtn").className = "noticebtn";
        document.getElementById("msgbtn").className = "msgbtn";
        document.getElementById("logbtn").className = "logbtn";
      }
      if (this.route == "/notice") {
        document.getElementById("raidbtn").className = "raidbtn";
        document.getElementById("noticebtn").className = "noticebtnclicked";
        document.getElementById("msgbtn").className = "msgbtn";
        document.getElementById("logbtn").className = "logbtn";
      }
      if (this.route == "/message") {
        document.getElementById("raidbtn").className = "raidbtn";
        document.getElementById("noticebtn").className = "noticebtn";
        document.getElementById("msgbtn").className = "msgbtnclicked";
        document.getElementById("logbtn").className = "logbtn";
      }
      if (this.route == "/log") {
        document.getElementById("raidbtn").className = "raidbtn";
        document.getElementById("noticebtn").className = "noticebtn";
        document.getElementById("msgbtn").className = "msgbtn";
        document.getElementById("logbtn").className = "logbtnclicked";
      }
    },
  },
  props: {
    route: {
      type: String,
      required: true,
      default: "/",
    },
  },
  computed: {
    _route: {
      get() {
        return this.route;
      },
      set() {
        return this.route;
      },
    },
  },
  mounted() {},
  methods: {
    setdefaultmenu() {
      document.getElementById("raidbtn").className = "raidbtnclicked";
    },
    goraid() {
      document.getElementById("raidbtn").className = "raidbtnclicked";
      document.getElementById("noticebtn").className = "noticebtn";
      document.getElementById("msgbtn").className = "msgbtn";
      document.getElementById("logbtn").className = "logbtn";

      this.$router.replace({ path: "/raid" });
    },
    gonotice() {
      document.getElementById("raidbtn").className = "raidbtn";
      document.getElementById("noticebtn").className = "noticebtnclicked";
      document.getElementById("msgbtn").className = "msgbtn";
      document.getElementById("logbtn").className = "logbtn";

      this.$router.replace({ path: "/notice" });
    },
    gomsg() {
      document.getElementById("raidbtn").className = "raidbtn";
      document.getElementById("noticebtn").className = "noticebtn";
      document.getElementById("msgbtn").className = "msgbtnclicked";
      document.getElementById("logbtn").className = "logbtn";

      this.$router.replace({ path: "/message" });
    },
    golog() {
      document.getElementById("raidbtn").className = "raidbtn";
      document.getElementById("noticebtn").className = "noticebtn";
      document.getElementById("msgbtn").className = "msgbtn";
      document.getElementById("logbtn").className = "logbtnclicked";
      this.$router.replace({ path: "/log" });
    },
    logout() {
      //this.$store.state.token = "";
      sessionStorage.clear();
      // this.$store.state.error.msg = "로그아웃되었습니다.";
      // this.$store.state.error.errorStatus = true;
      this.$router.go(0);
      //this.$store.state.error.errorStatus = false;
    },
  },
};
</script>

<style>
@font-face {
  font-family: "notosans";
  src: url("../assets/font/NotoSansKR-Bold.otf");
}

.otherscreen {
  width: 90%;
  height: 90vh;
}

.background {
  width: 100%;
  height: 100vh;
  background-image: linear-gradient(
    to bottom,
    rgb(204, 210, 240) 40%,
    rgb(238, 238, 238) 40%
  );
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  overflow: hidden;
  float: left;
}

.sidebackground {
  float: left;
  margin-left: 15px;
  margin-top: 15px;
  width: 240px;
  height: 820px;
  background-color: white;
  border-radius: 50px;
}

.sidemenulogo {
  margin-left: 10px;
  margin-top: 20px;
  background-image: url("../assets/machun_mini_logo.png");
  background-size: 180px;
  background-position: center;
  width: 220px;
  height: 100px;
}

#btnsameprop {
  width: 200px;
  height: 80px;
  background-color: white;
}

.raidbtn {
  width: 240px;
  height: 80px;
  background-color: white;
  background-image: url(../assets/raid_btn_back.png);
  background-size: 130px;
  background-position: 40px;
}

.noticebtn {
  width: 240px;
  height: 80px;
  background-color: white;
  background-image: url(../assets/notice_btn_back.png);
  background-size: 130px;
  background-position: 40px;
}

.msgbtn {
  width: 240px;
  height: 80px;
  background-color: white;
  background-image: url(../assets/message_btn_back.png);
  background-size: 130px;
  background-position: 40px;
}

.logbtn {
  width: 240px;
  height: 80px;
  background-color: white;
  background-image: url(../assets/logbtn.png);
  background-size: 130px;
  background-position: 40px;
}

.raidbtnclicked {
  width: 240px;
  height: 80px;
  background-color: rgb(93, 109, 190);
  background-image: url(../assets/raid_btn_back_clicked.png);
  background-size: 130px;
  background-position: 40px;
}

.noticebtnclicked {
  width: 240px;
  height: 80px;
  background-color: rgb(93, 109, 190);
  background-image: url(../assets/notice_btn_back_clicked.png);
  background-size: 130px;
  background-position: 40px;
}

.msgbtnclicked {
  width: 240px;
  height: 80px;
  background-color: rgb(93, 109, 190);
  background-image: url(../assets/message_btn_back_clicked.png);
  background-size: 130px;
  background-position: 40px;
}

.logbtnclicked {
  width: 240px;
  height: 80px;
  background-color: rgb(93, 109, 190);
  background-image: url(../assets/logbtnclicked.png);
  background-size: 130px;
  background-position: 40px;
}

#logoutbtn {
  font-family: "notosans";
  margin-left: 50px;
  width: 140px;
  height: 60px;
  font-size: 20px;
  background-color: rgb(93, 109, 190);
  color: white;
}

#companylogo {
  margin-left: 66px;
  background-image: url("../assets/company_logo.png");
  background-size: 100px;
  background-position: center;
  width: 100px;
  height: 50px;
}
</style>
import { instance, instanceAuth } from './url'

const login = async (loginData) => {
  return await instance
    .post('login', loginData, { credentials: true })
    .catch((err) => {
      return err.response
    })
}
// 레이드 확인
const raidInfo = async () => {
  return await instanceAuth.get('raid', { credentials: true }).catch((err) => {
    return err.response
  })
}
//보스 생성 리스트
const bossCreateList = async () => {
  return await instanceAuth
    .get('raid/manage/create/list', { credentials: true })
    .catch((err) => {
      return err.response
    })
}
//보스 레이드 예약 목록
const bossRaidList = async () => {
  return await instanceAuth
    .get('raid/manage/list', { credentials: true })
    .catch((err) => {
      return err.response
    })
}
// 레이드 생성
const raidMake = async (boss) => {
  return await instanceAuth
    .post('raid/manage/create', boss, { credentials: true })
    .catch((err) => {
      return err.response
    })
}
//레이드 삭제
const raidDelete = async (index) => {
  return await instanceAuth
    .delete(`raid/manage/${index}`, { credentials: true })
    .catch((err) => {
      return err.response
    })
}

//공지 확인
const noticeList = async () => {
  return await instanceAuth
    .get('notices', { credentials: true })
    .catch((err) => {
      return err.response
    })
}
// 공지사항 생성
const notice = async (data) => {
  return await instanceAuth
    .post('notices', data, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
    .catch((err) => {
      return err.response
    })
}
// 공지 변경
const noticeChange = async (data) => {
  return await instanceAuth
    .post('notices/update', data, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
    .catch((err) => {
      return err.response
    })
}
// 공지 사항 순서 변경
const noticeChangeOrder = async (data) => {
  return await instanceAuth
    .patch('notices/position', data, { credentials: true })
    .catch((err) => {
      return err.response
    })
}

// 공지 사항 삭제
const noticeDelete = async (data) => {
  return await instanceAuth
    .delete(`notices/${data}`, data, { credentials: true })
    .catch((err) => {
      return err.response
    })
}

//메시지 리스트 불러오기
const messageList = async () => {
  return await instanceAuth
    .get('/message/weblist', { credentials: true })
    .catch((err) => {
      return err.response
    })
}

//메시지 예약
const messageReserve = async (data) => {
  return await instanceAuth
    .post('/message/create', data, { credentials: true })
    .catch((err) => {
      return err.response
    })
}

const messageUpdate = async (data) => {
  return await instanceAuth
    .post('/message/update', data, { credentials: true })
    .catch((err) => {
      return err.response
    })
}

const messageDelete = async (data) => {
  return await instanceAuth
    .delete(`/message/delete/${data}`, data, { credentials: true })
    .catch((err) => {
      return err.response
    })
}

const nicknamecheck = async (data) => {
  return await instanceAuth
    .get(`/signup/nickname-check/${data}`, { credentials: true })
    .catch((err) => {
      return err.response
    })
}

const nowlength = async () => {
  return await instanceAuth
    .get('/raid/now/length', { credentials: true })
    .catch((err) => {
      return err.response
    })
}

const signuptypeusers = async () => {
  return await instanceAuth
    .get('/log/signUpType', { credentials: true })
    .catch((err) => {
      return err.response
    })
}

const dau = async (start, end) => {
  return await instanceAuth
    .get(`/log/loginCount/${start}/${end}`)
    .catch((err) => {
      return err.response
    })
}

const raidcount = async (start, end) => {
  return await instanceAuth
    .get(`/log/raidPlayCount/${start}/${end}`)
    .catch((err) => {
      return err.response
    })
}

const arcount = async (start, end, type) => {
  return await instanceAuth
    .get(`/log/contents/${start}/${end}/${type}`)
    .catch((err) => {
      return err.response
    })
}

const downloaddau = async (start, end) => {
  // return await instanceAuth
  //   .get(`/log/download/loginCount/${start}/${end}`)
  //   .catch((err) => {
  //     return err.response
  //   })
  return `${process.env.VUE_APP_CONNECT}/log/download/loginCount/${start}/${end}`
}

const downloadraidcount = async (start, end) => {
  // return await instanceAuth
  //   .get(`/log/download/raidPlayCount/${start}/${end}`)
  //   .catch((err) => {
  //     return err.response
  //   })

  return `${process.env.VUE_APP_CONNECT}/log/download/raidPlayCount/${start}/${end}`
}

const downloadarcount = async (start, end, type) => {
  // return await instanceAuth
  //   .get(`/log/download/contents/${start}/${end}/${type}`)
  //   .catch((err) => {
  //     return err.response
  //   })

  return `${process.env.VUE_APP_CONNECT}/log/download/contents/${start}/${end}/${type}`
}

export {
  login,
  raidInfo,
  bossCreateList,
  bossRaidList,
  raidMake,
  raidDelete,
  noticeList,
  notice,
  noticeChange,
  noticeChangeOrder,
  noticeDelete,
  messageList,
  messageReserve,
  messageUpdate,
  messageDelete,
  nicknamecheck,
  nowlength,
  signuptypeusers,
  dau,
  raidcount,
  arcount,
  downloaddau,
  downloadraidcount,
  downloadarcount
}
